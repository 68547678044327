h1, h2, p, ul {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cg-b,
.cg {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.cg-b {
  max-width: 144rem;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  display: block;
}

.pagination {
  max-width: 23rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-link {
  text-decoration: none;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F7FB;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  letter-spacing: 0.05em;
  color: #696969;
  transition: all .4s ease;
}

.pagination-link:hover {
  background-color: #B1AEBE;
  color: #fff;
}

.pagination-link:not(:last-of-type) {
  margin-right: 1rem;
}

.pagination-link.link-active {
  background-color: #B1AEBE;
  color: #fff;
}

.pagination-link.link-more {
  background-color: unset;
  color: #696969;
}

.header {
  background-color: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.header-wrap {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media only screen and (max-width: 575px) {
  .header-wrap {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.header-wrap__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 17rem;
  height: 4rem;
}

@media only screen and (max-width: 1199px) {
  .header-wrap__logo {
    position: relative;
    top: unset;
    left: unset;
    transform: unset;
  }
}

@media only screen and (max-width: 575px) {
  .header-wrap__logo {
    width: 13rem;
    height: 3.05rem;
  }
}

.header-wrap .header-autorized {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #B1AEBE;
  margin-right: 2rem;
}

.header-wrap .header-autorized__icon {
  margin-right: .5rem;
  width: 2rem;
  height: 2rem;
}

.header-wrap__login:link, .header-wrap__login:visited {
  text-decoration: none;
  width: 100%;
  max-width: 20rem;
  height: 5rem;
  background-color: #59579A;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #FFFFFF;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .header-wrap__login:link, .header-wrap__login:visited {
    font-size: 1rem;
    height: 4rem;
    padding: 1.2rem;
  }
}

.first {
  background: #F5F7FB;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 2rem;
}

.first-wrap__ttl {
  font-size: 3.6rem;
  line-height: 4.2rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #000;
}

@media only screen and (max-width: 575px) {
  .first-wrap__ttl {
    font-size: 2.4rem;
    line-height: 3rem;
  }
}

.first-wrap__subttl {
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: #696969;
}

@media only screen and (max-width: 575px) {
  .first-wrap__subttl {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.first-filters {
  margin-bottom: 5rem;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .first-filters {
    margin-bottom: 2rem;
  }
}

.first-filters .filters-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 5rem;
  border-radius: 10rem;
  background-color: #F7F7FA;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.2);
}

@media only screen and (min-width: 1200px) {
  .first-filters .filters-btn {
    display: none;
  }
}

.first-filters .filters-form {
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .first-filters .filters-form {
    display: flex !important;
  }
}

@media only screen and (max-width: 1199px) {
  .first-filters .filters-form {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
    background-color: #fff;
    padding-top: 2rem;
  }
}

.first-filters .filters-form .input-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .first-filters .filters-form .input-wrap {
    margin-bottom: 1.6rem;
  }
}

.first-filters .filters-form .input-wrap .input-txt__label {
  display: block;
  width: 3rem;
  height: 3rem;
  background-color: #F7F7FA;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  margin-right: 1rem;
}

.first-filters .filters-form .input-wrap .input-txt__label::after {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background-image: url("../img/icon/icon-find.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.first-filters .filters-form .input-wrap .input-txt {
  font-family: "HC";
  border: unset;
  background-color: unset;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #000000;
  padding-right: 2rem;
}

.first-filters .filters-form .input-wrap .input-txt::placeholder {
  color: #000;
}

.first-filters .filters-form .select-wrap {
  padding: 0 3rem;
  height: 5rem;
  display: flex;
  align-items: center;
  position: relative;
  width: 20%;
}

@media only screen and (max-width: 1439px) {
  .first-filters .filters-form .select-wrap {
    padding: 0 1.5rem;
  }
}

@media only screen and (max-width: 1199px) {
  .first-filters .filters-form .select-wrap {
    margin-bottom: 1.6rem;
    width: 100%;
  }
}

.first-filters .filters-form .select-wrap::after {
  content: "";
  height: 100%;
  width: .1rem;
  border-radius: .5rem;
  background-color: #E5E5E5;
  position: absolute;
  left: 0;
}

.first-filters .filters-form .input-btn {
  height: 5rem;
  background: #59579A;
  border-radius: 10rem;
  padding: 1.8rem 8rem;
  border: unset;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.select2-container {
  width: 100% !important;
  max-width: 25rem;
}

.header-wrap__lang {
  width: 100%;
  max-width: 4rem;
  margin-right: 1rem;
}

@media only screen and (max-width: 1199px) {
  .header-wrap__lang {
    margin-left: auto;
  }
}

.header-wrap__lang .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #B1AEBE;
  font-family: "HC-bold";
}

.header-wrap__lang .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("../img/icon/icon-select-arrow-silver.svg");
}

.select-wrap:nth-child(2) .select2-container {
  max-width: 10rem;
}

@media only screen and (max-width: 1199px) {
  .select-wrap:nth-child(2) .select2-container {
    max-width: 14rem;
  }
}

.select-wrap:nth-child(3) .select2-container {
  max-width: 13.5rem;
}

@media only screen and (max-width: 1199px) {
  .select-wrap:nth-child(3) .select2-container {
    max-width: 14rem;
  }
}

.select-wrap:nth-child(4) .select2-container {
  max-width: 7.5rem;
}

@media only screen and (max-width: 1199px) {
  .select-wrap:nth-child(4) .select2-container {
    max-width: 14rem;
  }
}

.select-wrap:nth-child(5) .select2-container {
  max-width: 5.5rem;
}

@media only screen and (max-width: 1199px) {
  .select-wrap:nth-child(5) .select2-container {
    max-width: 14rem;
  }
}

.select2-container--default .select2-selection--single {
  background-color: unset;
  border: unset;
  border-radius: unset;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #000;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: unset;
  background-image: url("../img/icon/icon-select-arrow.svg");
  background-repeat: no-repeat;
  width: .7rem;
  height: .6rem;
  transition: all .4s ease;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border: unset;
  transform: rotate(-180deg);
}

.select2-dropdown {
  border: unset;
  box-shadow: 0px 0.4rem 2rem rgba(89, 87, 154, 0.5);
  border-radius: .5rem;
}

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  top: 1rem !important;
}

.select2-results__option[aria-selected] {
  font-family: "HC";
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #696969;
  text-align: center;
  transition: all .4s ease;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: unset;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #59579A;
  background-color: unset;
}

.select2-results__option:first-of-type.select2-results__option--highlighted[aria-selected] {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.select2-results__option:last-of-type.select2-results__option--highlighted[aria-selected] {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 70%;
  height: 60%;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

@media only screen and (max-width: 1440px) {
  .modal-content {
    width: 60%;
    height: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .modal-content {
    width: 90%;
    height: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .modal-content {
    height: 36%;
  }
}

.modal-content iframe {
  width: 100%;
  height: 100%;
}

.modal-content .close {
  position: absolute;
  top: -5%;
  right: -3%;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #fff;
}

.modal-content .close::before, .modal-content .close::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 0.3rem;
  height: 80%;
  background-color: #000;
}

.modal-content .close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@-webkit-keyframes animatetop {
  from {
    top: -30rem;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -30rem;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.first-trainer {
  margin-bottom: 12rem;
}

@media only screen and (max-width: 991px) {
  .first-trainer {
    margin-bottom: 5rem;
  }
}

.first-trainer .t-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
  max-width: 100%;
  padding: 3rem 2rem 3rem 5rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.2);
  border-radius: .5rem;
  margin-bottom: 1rem;
  position: relative;
  transition: all .4s ease;
}

.first-trainer .t-block:hover {
  box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.4);
}

.first-trainer .t-block:last-of-type {
  margin-bottom: 5rem;
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block:last-of-type {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 1439px) {
  .first-trainer .t-block {
    padding: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block {
    flex-direction: column;
  }
}

.first-trainer .t-block .block-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 2rem;
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-left {
    order: 2;
    padding-right: 0;
  }
}

.first-trainer .t-block .block-left__characteristic {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 1199px) {
  .first-trainer .t-block .block-left__characteristic {
    max-width: 60rem;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-left__characteristic {
    max-width: unset;
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .first-trainer .t-block .block-left__characteristic {
    flex-wrap: unset;
    flex-direction: column;
  }
}

.first-trainer .t-block .block-left__characteristic .characteristic-info {
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #696969;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 1199px) {
  .first-trainer .t-block .block-left__characteristic .characteristic-info {
    margin-bottom: 2rem;
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .first-trainer .t-block .block-left__characteristic .characteristic-info {
    width: 100%;
    margin-bottom: 1.6rem;
  }
}

.first-trainer .t-block .block-left__characteristic .characteristic-info:not(:last-of-type) {
  margin-right: 5rem;
}

@media only screen and (max-width: 1439px) {
  .first-trainer .t-block .block-left__characteristic .characteristic-info:not(:last-of-type) {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 1199px) {
  .first-trainer .t-block .block-left__characteristic .characteristic-info:not(:last-of-type) {
    margin-right: 0em;
  }
}

.first-trainer .t-block .block-left__characteristic .characteristic-info .bolder {
  color: #000;
  margin-left: .5rem;
}

.first-trainer .t-block .block-left__characteristic .characteristic-info .characteristic-icon {
  position: relative;
  width: 3rem;
  height: 3rem;
  background: #F5F7FB;
  border-radius: 50%;
  margin-right: 1rem;
}

.first-trainer .t-block .block-left__characteristic .characteristic-info .characteristic-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.4rem;
  height: 1.4rem;
  background-image: url("../img/icon/icon-book.png");
  background-repeat: no-repeat;
}

.first-trainer .t-block .block-left .desc-link__wrap {
  text-decoration: none;
}

.first-trainer .t-block .block-left__desc {
  max-width: 100.9rem;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #000000;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 575px) {
  .first-trainer .t-block .block-left__desc {
    font-size: 1.4rem;
  }
}

.first-trainer .t-block .block-left__links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 575px) {
  .first-trainer .t-block .block-left__links {
    flex-direction: column;
    align-items: flex-start;
  }
}

.first-trainer .t-block .block-left__links .links {
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #59579A;
  border-bottom: 1px solid #59579A;
  padding-bottom: .3rem;
}

@media only screen and (max-width: 575px) {
  .first-trainer .t-block .block-left__links .links {
    font-size: 1.4rem;
  }
}

.first-trainer .t-block .block-left__links .links:not(:last-of-type) {
  margin-right: 3rem;
}

@media only screen and (max-width: 575px) {
  .first-trainer .t-block .block-left__links .links:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.first-trainer .t-block .block-left__links .links.link-video {
  position: relative;
  margin-left: 3rem;
}

.first-trainer .t-block .block-left__links .links.link-video::after {
  content: "";
  background-image: url("../img/icon/icon-yt.svg");
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: -13%;
  transform: translateY(-50%);
  width: 1.972rem;
  height: 1.4rem;
}

.first-trainer .t-block .block-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 5rem;
  position: relative;
}

@media only screen and (max-width: 1439px) {
  .first-trainer .t-block .block-right {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-right {
    width: 100%;
    padding-left: 0;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
}

.first-trainer .t-block .block-right::after {
  content: "";
  width: .1rem;
  height: 100%;
  background-color: #E5E5E5;
  border-radius: .5rem;
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-right::after {
    width: 100%;
    height: .1rem;
    bottom: 0;
  }
}

.first-trainer .t-block .block-right__wrap {
  width: 10rem;
  height: 10rem;
  margin-bottom: 2rem;
  border-radius: 50%;
  box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.5);
}

.first-trainer .t-block .block-right__wrap img {
  border-radius: 50%;
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-right__wrap {
    margin-bottom: 1.6rem;
  }
}

.first-trainer .t-block .block-right__name {
  font-family: "HC-bold";
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #000000;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-right__name {
    margin-bottom: 1.6rem;
  }
}

.first-trainer .t-block .block-right__price {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #000000;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 991px) {
  .first-trainer .t-block .block-right__price {
    margin-bottom: 1.6rem;
  }
}

.first-trainer .t-block .block-right__get {
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 20rem;
  height: 5rem;
  background: #F26725;
  border-radius: 10rem;
}

.first-features {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 6rem;
}

@media only screen and (max-width: 991px) {
  .first-features {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 767px) {
  .first-features {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.first-features__block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-features__block:not(:last-of-type) {
  margin-right: 21.7rem;
}

@media only screen and (max-width: 1439px) {
  .first-features__block:not(:last-of-type) {
    margin-right: 15rem;
  }
}

@media only screen and (max-width: 1199px) {
  .first-features__block:not(:last-of-type) {
    margin-right: 10rem;
  }
}

@media only screen and (max-width: 991px) {
  .first-features__block:not(:last-of-type) {
    margin-right: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .first-features__block:not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 5rem;
  }
}

.first-features__block .feature-wrap {
  width: 25rem;
  height: 25rem;
  background: #F5F7FB;
  border-radius: 50%;
  margin-bottom: 5rem;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .first-features__block .feature-wrap {
    width: 20rem;
    height: 20rem;
  }
}

@media only screen and (max-width: 767px) {
  .first-features__block .feature-wrap {
    margin-bottom: 2rem;
  }
}

.first-features__block .feature-wrap .feature-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.first-features__block .feature-wrap .feature-img.f-img1 {
  top: 43%;
  left: 45%;
}

@media only screen and (max-width: 991px) {
  .first-features__block .feature-wrap .feature-img.f-img1 {
    width: 9rem;
  }
}

.first-features__block .feature-wrap .feature-img.f-img2 {
  top: 64%;
  left: 62%;
}

@media only screen and (max-width: 991px) {
  .first-features__block .feature-wrap .feature-img.f-img2 {
    width: 10rem;
  }
}

.first-features__block .feature-wrap .feature-img.f-img3 {
  left: 57%;
}

@media only screen and (max-width: 991px) {
  .first-features__block .feature-wrap .feature-img.f-img3 {
    width: 12rem;
    left: 59%;
  }
}

@media only screen and (max-width: 991px) {
  .first-features__block .feature-wrap .feature-img.f-img4 {
    width: 12rem;
  }
}

.first-features__block .feature-ttl {
  font-family: "HC-bold";
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #59579A;
}

.trainer-bg__wrap {
  position: relative;
  overflow: hidden;
}

.trainer-bg__wrap .calendar-bg {
  position: absolute;
  z-index: -1;
  bottom: -10%;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 1439px) {
  .trainer-bg__wrap .calendar-bg {
    bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .trainer-bg__wrap .calendar-bg {
    display: none;
  }
}

.trainer-wrap {
  position: relative;
}

.trainer-cards__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.trainer-cards {
  padding-top: 5rem;
  padding-bottom: 3rem;
}

@media only screen and (max-width: 575px) {
  .trainer-cards {
    padding-top: 3rem;
  }
}

.trainer-cards__main {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0.4rem 2rem rgba(89, 87, 154, 0.1);
  border-radius: .5rem;
  margin-bottom: 3rem;
  padding: 5rem;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1199px) {
  .trainer-cards__main {
    padding: 3rem;
  }
}

@media only screen and (max-width: 991px) {
  .trainer-cards__main {
    padding: 2rem;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-cards__main {
    flex-direction: column;
  }
}

.trainer-cards__main .main-left {
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 1023px) {
  .trainer-cards__main .main-left {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-cards__main .main-left {
    justify-content: center;
    margin-bottom: 2.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-cards__main .main-left {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
  }
}

.trainer-cards__main .main-left__wrap {
  width: 20rem;
  height: 20rem;
  margin-right: 7rem;
  border-radius: 50%;
  box-shadow: 0 0.4rem 2rem rgba(89, 87, 154, 0.5);
}

@media only screen and (max-width: 1199px) {
  .trainer-cards__main .main-left__wrap {
    margin-right: 3.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .trainer-cards__main .main-left__wrap {
    width: 15rem;
    height: 15rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-cards__main .main-left__wrap {
    margin-right: 0;
  }
}

.trainer-cards__main .main-left__wrap img {
  border-radius: 50%;
}

.trainer-cards__main .main-left__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 991px) {
  .trainer-cards__main .main-left__info {
    max-width: 23rem;
  }
}

.trainer-cards__main .main-left__info .info-name {
  font-family: "HC-bold";
  font-size: 4.8rem;
  line-height: 5.5rem;
  color: #59579A;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 1023px) {
  .trainer-cards__main .main-left__info .info-name {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .trainer-cards__main .main-left__info .info-name {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
}

.trainer-cards__main .main-left__info .info-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  max-width: 47rem;
}

@media only screen and (max-width: 1199px) {
  .trainer-cards__main .main-left__info .info-options {
    max-width: 44rem;
  }
}

.trainer-cards__main .main-left__info .info-options__option {
  width: 50%;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #696969;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
}

@media only screen and (max-width: 1023px) {
  .trainer-cards__main .main-left__info .info-options__option {
    width: 100%;
    margin-top: 1rem;
  }
}

.trainer-cards__main .main-left__info .info-options__option .bolder {
  color: #000;
  margin-left: .5rem;
}

.trainer-cards__main .main-left__info .info-options__option .option-icon {
  position: relative;
  width: 3rem;
  height: 3rem;
  background: #F5F7FB;
  border-radius: 50%;
  margin-right: 1rem;
}

.trainer-cards__main .main-left__info .info-options__option .option-icon::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.4rem;
  height: 1.4rem;
  background-image: url("../img/icon/icon-book.png");
  background-repeat: no-repeat;
}

.trainer-cards__main .main-right {
  padding-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .trainer-cards__main .main-right {
    padding-left: 2.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-cards__main .main-right {
    padding-left: 0;
    padding-top: 2.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-cards__main .main-right {
    padding-top: 1.5rem;
  }
}

.trainer-cards__main .main-right::after {
  content: "";
  width: .1rem;
  height: 100%;
  background-color: #E5E5E5;
  border-radius: .5rem;
  position: absolute;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .trainer-cards__main .main-right::after {
    width: 100%;
    height: .1rem;
    top: 0;
  }
}

.trainer-cards__main .main-right__price {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #000000;
  margin-bottom: 2rem;
}

.trainer-cards__main .main-right__booking {
  outline: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 20rem;
  height: 5rem;
  background: #F26725;
  border-radius: 10rem;
}

.trainer-cards .video-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .trainer-cards .video-wrap {
    flex-direction: column;
    align-items: center;
  }
}

.trainer-cards__video {
  width: 100%;
  max-width: 70rem;
  height: 39.4rem;
  background-image: url("../img/trainer-video__bg.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.1);
  margin-right: 3rem;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .trainer-cards__video {
    margin-right: 0;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-cards__video {
    height: 32rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-cards__video {
    height: 28rem;
  }
}

.trainer-cards__video .video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6rem;
  height: 6rem;
  background-image: url("../img/icon/trainer-yt-play.svg");
  cursor: pointer;
}

.trainer-cards__list {
  width: 100%;
  max-width: 63.5rem;
  min-height: 39.4rem;
  box-shadow: 0 0.4rem 2rem rgba(89, 87, 154, 0.1);
  border-radius: .5rem;
}

@media only screen and (max-width: 991px) {
  .trainer-cards__list {
    min-height: 34rem;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-cards__list {
    min-height: 30rem;
  }
}

.trainer-cards__list .list-ttl {
  display: block;
  width: 100%;
  font-family: "HC-bold";
  font-size: 2.4rem;
  line-height: 2.7rem;
  text-align: center;
  color: #B1AEBE;
  background-color: #F5F7FB;
  padding: 2.2rem 0;
}

@media only screen and (max-width: 575px) {
  .trainer-cards__list .list-ttl {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
}

.trainer-cards__list .list-items {
  background-color: #fff;
  padding: 3rem 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media only screen and (max-width: 1199px) {
  .trainer-cards__list .list-items {
    padding: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-cards__list .list-items {
    padding: 2rem 1rem;
    flex-direction: column;
  }
}

.trainer-cards__list .list-items ul {
  list-style: none;
  padding: 0;
}

.trainer-cards__list .list-items ul:first-of-type {
  margin-right: 5rem;
}

@media only screen and (max-width: 1199px) {
  .trainer-cards__list .list-items ul:first-of-type {
    margin-right: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .trainer-cards__list .list-items ul:first-of-type {
    margin-right: 5rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-cards__list .list-items ul:first-of-type {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.trainer-cards__list .list-items ul li {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #59579A;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .trainer-cards__list .list-items ul li {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.trainer-cards__list .list-items ul li:not(:last-of-type) {
  margin-bottom: 3rem;
}

@media only screen and (max-width: 767px) {
  .trainer-cards__list .list-items ul li:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.trainer-cards__list .list-items ul li::after {
  content: "";
  height: .1rem;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -.5rem;
  background-color: #59579A;
  border-radius: .5rem;
}

.trainer-info {
  background: #F5F7FB;
  border-radius: .5rem;
  padding: 5rem 0;
  margin-bottom: 6.7rem;
}

@media only screen and (max-width: 991px) {
  .trainer-info {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-info {
    padding: 3rem 0;
  }
}

.trainer-info__block .info {
  column-count: 2;
  column-gap: 8rem;
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #000000;
}

@media only screen and (max-width: 1199px) {
  .trainer-info__block .info {
    column-gap: 4rem;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-info__block .info {
    column-count: 1;
  }
}

@media only screen and (max-width: 575px) {
  .trainer-info__block .info {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.trainer-info .trainer-more {
  width: 100%;
  height: 2rem;
  position: relative;
}

.trainer-info .trainer-more .more,
.trainer-info .trainer-more .less {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #59579A;
  position: absolute;
  bottom: 0;
  left: 56.5%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 1439px) {
  .trainer-info .trainer-more .more,
  .trainer-info .trainer-more .less {
    left: 57%;
  }
}

@media only screen and (max-width: 991px) {
  .trainer-info .trainer-more .more,
  .trainer-info .trainer-more .less {
    left: 59%;
  }
}

@media only screen and (max-width: 767px) {
  .trainer-info .trainer-more .more,
  .trainer-info .trainer-more .less {
    left: 0;
    transform: unset;
  }
}

.trainer-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.trainer-calendar__ttl {
  font-family: "HC-bold";
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #B1AEBE;
  margin-bottom: 3rem;
}

@media only screen and (max-width: 575px) {
  .trainer-calendar__ttl {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
}

.trainer-calendar .calendar-info {
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #000000;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 575px) {
  .trainer-calendar .calendar-info {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.trainer-calendar .calendar-buy {
  margin-bottom: 2rem;
  width: 20rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F26725;
  border-radius: 10rem;
  border: none;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.trainer-calendar .calendar-desc {
  width: 100%;
  max-width: 39.9rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: #696969;
}

@media only screen and (max-width: 575px) {
  .trainer-calendar .calendar-desc {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

.calendar-block {
  width: 100%;
  max-width: 73rem;
  min-height: 40.6rem;
  background: #FFFFFF;
  box-shadow: 0 0.4rem 2rem rgba(89, 87, 154, 0.1);
  border-radius: .5rem;
  padding: 2.6rem 2rem 2rem 2rem;
  margin-bottom: 2rem;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .calendar-block {
    padding: 2.6rem 0 0 0;
  }
}

.calendar-block .calendar-block__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  background-color: #F5F7FB;
}

.calendar-block .calendar-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 57rem;
  margin: 0 auto;
}

.calendar-block .calendar-form__week {
  font-family: "HC-bold";
  font-size: 1.8rem;
  line-height: 2.1rem;
  color: #59579A;
  margin-bottom: 4.6rem;
  width: 100%;
  display: block;
  text-align: center;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__week {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.calendar-block .calendar-form__days {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  max-width: 57rem;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__days {
    justify-content: center;
  }
}

.calendar-block .calendar-form__days .booking-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__days .booking-block:not(:last-of-type) {
    margin-right: 1.5rem;
  }
}

.calendar-block .calendar-form__days .booking-block__day {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: all .2s ease;
  cursor: pointer;
  margin-bottom: 3.1rem;
}

.calendar-block .calendar-form__days .booking-block__day > .calendar-day:checked ~ .day-name::after {
  opacity: 1;
}

.calendar-block .calendar-form__days .booking-block__day > .calendar-day:checked ~ .day-name,
.calendar-block .calendar-form__days .booking-block__day > .calendar-day:checked ~ .day-date {
  color: #fff;
}

.calendar-block .calendar-form__days .booking-block__day:hover .day-name,
.calendar-block .calendar-form__days .booking-block__day:hover .day-date {
  color: #fff;
}

.calendar-block .calendar-form__days .booking-block__day:hover .day-name::after {
  opacity: 1;
}

.calendar-block .calendar-form__days .booking-block__day .day-name {
  margin-bottom: 1.1rem;
}

.calendar-block .calendar-form__days .booking-block__day .day-name::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  height: 6.7rem;
  width: 5.9rem;
  background: #59579A;
  border-radius: 2rem;
  opacity: 0;
  transition: all .4s ease;
}

@media only screen and (max-width: 767px) {
  .calendar-block .calendar-form__days .booking-block__day .day-name::after {
    height: 5.5rem;
    width: 4.3rem;
  }
}

@media only screen and (max-width: 767px) {
  .calendar-block .calendar-form__days .booking-block__day .day-name::after {
    height: 5rem;
    width: 3rem;
  }
}

.calendar-block .calendar-form__days .booking-block__day .day-name,
.calendar-block .calendar-form__days .booking-block__day .day-date {
  font-family: "HC-bold";
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #B1AEBE;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__days .booking-block__day .day-name,
  .calendar-block .calendar-form__days .booking-block__day .day-date {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

.calendar-block .calendar-form__days .booking-block__time {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-block .calendar-form__days .booking-block__time .time {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendar-block .calendar-form__days .booking-block__time .time > .time-checkbox:disabled ~ .time-value {
  color: #CAC9EB;
  pointer-events: none;
  cursor: not-allowed;
}

.calendar-block .calendar-form__days .booking-block__time .time > .time-checkbox:disabled ~ .time-value::after {
  opacity: 0;
}

.calendar-block .calendar-form__days .booking-block__time .time > .time-checkbox:checked ~ .time-value {
  color: #fff;
}

.calendar-block .calendar-form__days .booking-block__time .time > .time-checkbox:checked ~ .time-value::after {
  opacity: 1;
}

.calendar-block .calendar-form__days .booking-block__time .time:hover > .time-checkbox:checked::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: -2.2rem;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("../img/icon/icod-arr-desc.svg");
  background-repeat: no-repeat;
  width: 1.2rem;
  height: 1.2rem;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__days .booking-block__time .time:hover > .time-checkbox:checked::after {
    display: none;
  }
}

.calendar-block .calendar-form__days .booking-block__time .time:hover > .time-checkbox:checked::before {
  content: attr(data-title);
  width: 16rem;
  height: 4.5rem;
  position: absolute;
  z-index: 2;
  top: -6.5rem;
  left: 50%;
  transform: translateX(-50%);
  font-family: "HC";
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #F26725;
  background-color: #fff;
  border-radius: .5rem;
  box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__days .booking-block__time .time:hover > .time-checkbox:checked::before {
    font-size: 1.2rem;
    line-height: 1.4rem;
    top: unset;
    bottom: 1rem;
    width: 11rem;
    height: 3rem;
  }
}

.calendar-block .calendar-form__days .booking-block__time .time:hover .time-value {
  color: #fff;
}

.calendar-block .calendar-form__days .booking-block__time .time:hover .time-value::after {
  opacity: 1;
}

.calendar-block .calendar-form__days .booking-block__time .time-checkbox {
  position: relative;
}

.calendar-block .calendar-form__days .booking-block__time .time-value {
  font-family: "HC-bold";
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #59579A;
  position: relative;
  transition: all .2s ease;
  cursor: pointer;
}

@media only screen and (max-width: 575px) {
  .calendar-block .calendar-form__days .booking-block__time .time-value {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

.calendar-block .calendar-form__days .booking-block__time .time-value::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5.9rem;
  height: 4rem;
  background: #F26725;
  border-radius: 2rem;
  opacity: 0;
  transition: all .4s ease;
}

@media only screen and (max-width: 767px) {
  .calendar-block .calendar-form__days .booking-block__time .time-value::after {
    width: 5rem;
    height: 3rem;
  }
}

.slick-prev,
.slick-next {
  right: 8.5rem;
  z-index: 3;
  top: .8rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #FFF;
}

@media only screen and (max-width: 575px) {
  .slick-prev,
  .slick-next {
    right: 3rem;
  }
}

@media only screen and (max-width: 374px) {
  .slick-prev,
  .slick-next {
    right: 1rem;
  }
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #fff;
}

.slick-prev::after,
.slick-next::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/icon/slider-btn-arr.svg");
  background-repeat: no-repeat;
  width: .5rem;
  height: .8rem;
}

.slick-prev {
  left: unset;
  right: 12.5rem;
}

@media only screen and (max-width: 575px) {
  .slick-prev {
    right: 7.5rem;
  }
}

@media only screen and (max-width: 374px) {
  .slick-prev {
    right: unset;
    left: 1rem;
  }
}

.slick-prev::after {
  transform: translate(-50%, -50%) rotate(-180deg);
}

.booking-wrap {
  position: relative;
}

.booking-wrap .booking-bg {
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: -10%;
}

@media only screen and (max-width: 1439px) {
  .booking-wrap .booking-bg {
    bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .booking-wrap .booking-bg {
    display: none;
  }
}

.booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

.booking-trainer {
  width: 100%;
  max-width: 73rem;
  background: #F5F7FB;
  border-radius: .5rem;
  padding: 3rem 6.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 575px) {
  .booking-trainer {
    padding: 2rem;
    flex-direction: column;
  }
}

.booking-trainer__wrap {
  width: 100%;
  max-width: 10rem;
  height: 10rem;
  margin-right: 3rem;
  border-radius: 50%;
}

@media only screen and (max-width: 575px) {
  .booking-trainer__wrap {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.booking-trainer__wrap img {
  border-radius: 50%;
}

.booking-trainer__info {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 575px) {
  .booking-trainer__info {
    align-items: center;
  }
}

.booking-trainer__info .info-name {
  font-family: "HC-bold";
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #B1AEBE;
  margin-bottom: 1rem;
}

.booking-trainer__info .info-txt {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #696969;
}

@media only screen and (max-width: 575px) {
  .booking-trainer__info .info-txt {
    text-align: center;
  }
}

.booking-form {
  width: 100%;
  max-width: 73rem;
  background: #FFFFFF;
  box-shadow: 0 0.4rem 2rem rgba(89, 87, 154, 0.1);
  border-radius: .5rem;
  padding: 4rem 6.8rem 3rem 6.8rem;
  margin-bottom: 13.5rem;
  position: relative;
}

@media only screen and (max-width: 575px) {
  .booking-form {
    padding: 3rem;
  }
}

@media only screen and (max-width: 423px) {
  .booking-form {
    padding: 2rem;
  }
}

.booking-form__steps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  position: relative;
}

.booking-form__steps::after {
  content: "";
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("../img/booking-dashed-line.svg");
  background-repeat: no-repeat;
  width: 100%;
  max-width: 30.4rem;
  height: .1rem;
}

@media only screen and (max-width: 767px) {
  .booking-form__steps::after {
    max-width: 21rem;
  }
}

@media only screen and (max-width: 575px) {
  .booking-form__steps::after {
    top: 30%;
    max-width: 19rem;
  }
}

@media only screen and (max-width: 423px) {
  .booking-form__steps::after {
    max-width: 15rem;
  }
}

.booking-form__steps .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.booking-form__steps .step-number {
  margin-bottom: 1rem;
  width: 4.8rem;
  height: 4.8rem;
  background-color: #F5F7FB;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.booking-form__steps .step-number::after {
  content: "";
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
  height: 3rem;
  background: #CAC9EB;
  border-radius: 50%;
  transition: all .4s ease;
}

.booking-form__steps .step-number__wrap {
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #FFFFFF;
  position: relative;
  z-index: 1;
}

.booking-form__steps .step-txt {
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #B1AEBE;
  transition: all .4s ease;
}

@media only screen and (max-width: 575px) {
  .booking-form__steps .step-txt {
    font-size: 1.2rem;
    line-height: 1.4rem;
    max-width: 9rem;
    text-align: center;
  }
}

.booking-form__steps .step.active > .step-number::after {
  background: #59579A;
}

.booking-form__steps .step.active > .step-txt {
  color: #59579A;
}

.booking-form .tab {
  display: none;
}

.booking-form .tab-forms {
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 48.3%));
  grid-gap: 2rem;
}

@media only screen and (max-width: 767px) {
  .booking-form .tab-forms {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 48.7%));
    grid-gap: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .booking-form .tab-forms {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 100%));
  }
}

.booking-form .tab-forms .booking-inp__wrap {
  position: relative;
}

.booking-form .tab-forms .booking-inp__wrap:first-of-type {
  grid-column: 1 / -1;
}

.booking-form .tab-forms .booking-inp__wrap .booking-inp {
  width: 100%;
}

.booking-form .tab-forms .select-booking__wrap {
  width: 100%;
  height: 5.9rem;
  background: #F5F7FB;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
}

@media only screen and (max-width: 575px) {
  .booking-form .tab-forms .select-booking__wrap .select2-container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .booking-form .tab-forms .select-booking__wrap {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .booking-form .tab-forms .select-booking__wrap {
    padding: 0 2rem;
  }
}

.booking-form .tab-calendar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.booking-form .tab-calendar > .calendar-block {
  box-shadow: unset;
}

.booking-form__info {
  position: absolute;
  bottom: -4rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;
  color: #000000;
}

.booking-form__submit {
  position: absolute;
  bottom: 6.5rem;
  left: 50%;
  transform: translateX(-50%);
}

.booking-form__submit .submit-btn {
  border: none;
  width: 20rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F26725;
  border-radius: 10rem;
  font-family: "HC-bold";
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
}

.booking-form__desc {
  position: absolute;
  bottom: -16rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 39.9rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: #696969;
}

.error {
  position: absolute;
  bottom: 0;
  left: 0;
}

.form-inp {
  border: unset;
  height: 5.9rem;
  background: #F5F7FB;
  border-radius: .5rem;
  padding: 2rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
}

@media only screen and (max-width: 767px) {
  .form-inp {
    padding: 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .form-inp {
    padding: 2rem;
  }
}

.form-inp::placeholder {
  color: #B1AEBE;
}

.footer {
  background-color: #59579A;
}

.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 991px) {
  .footer-wrap {
    flex-direction: column;
    align-items: center;
  }
}

.footer-wrap__logo {
  width: 8.4rem;
  height: 8rem;
}

.footer-wrap__copy {
  margin-left: 3rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #FFFFFF;
}

@media only screen and (max-width: 991px) {
  .footer-wrap__copy {
    margin-left: 0;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .footer-wrap__copy {
    text-align: center;
    margin-top: 2rem;
    line-height: 1.8rem;
  }
}

.footer-wrap__more {
  margin-left: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .footer-wrap__more {
    margin-top: 3rem;
    width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .footer-wrap__more {
    flex-wrap: wrap;
    max-width: 27rem;
    margin: 2rem auto 0 auto;
  }
}

.footer-wrap__more .more-link {
  text-decoration: none;
  margin-right: 3rem;
  font-family: "HC-bold";
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: #F26725;
}

@media only screen and (max-width: 575px) {
  .footer-wrap__more .more-link {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.footer-wrap__more .more-social {
  text-decoration: none;
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #47458E;
}

.footer-wrap__more .more-social::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
}

.footer-wrap__more .more-social.facebook::after {
  content: "";
  width: 1rem;
  height: 2rem;
  background-image: url("../img/icon/icon-fb.png");
}

.footer-wrap__more .more-social.insta::after {
  width: 2rem;
  height: 2rem;
  content: "";
  background-image: url("../img/icon/icon-insta.png");
}

.footer-wrap__more .more-social:not(:last-child) {
  margin-right: 1rem;
}
