// CUSTOM //
h1, h2, p, ul {
    margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.cg-b,
.cg {
    width: 100%;
    max-width: 140rem;
    margin: 0 auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.cg-b {
    max-width: 144rem;
}
.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    display: block;
}
// END //

// PAGINTION //
.pagination {
    max-width: 23rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-link {
        text-decoration: none;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F5F7FB;

        font-size: 1.4rem;
        line-height: 1.6rem;
        text-align: center;
        letter-spacing: 0.05em;
        color: #696969;
        transition: all .4s ease;

        &:hover {
            background-color: #B1AEBE;
            color: #fff;
        }

        &:not(:last-of-type) {
            margin-right: 1rem;
        }

        &.link-active {
            background-color: #B1AEBE;
            color: #fff;
        }

        &.link-more {
            background-color: unset;
            color: #696969;
        }
    }
}
// END //

// HEADER //
.header {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    &-wrap {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;

        @media only screen and (max-width: 575px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        &__logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 17rem;
            height: 4rem;

            @media only screen and (max-width: 1199px) {
                position: relative;
                top: unset;
                left: unset;
                transform: unset;
            }

            @media only screen and (max-width: 575px) {
                width: 13rem;
                height: 3.05rem;
            }
        }

        .header-autorized {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: "HC-bold";
            font-size: 1.4rem;
            line-height: 1.6rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #B1AEBE;
            margin-right: 2rem;

            &__icon {
                margin-right: .5rem;
                width: 2rem;
                height: 2rem;
            }
        }

        &__login:link,
        &__login:visited {
            text-decoration: none;
            width: 100%;
            max-width: 20rem;
            height: 5rem;
            background-color: #59579A;
            border-radius: 10rem;
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "HC-bold";
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: #FFFFFF;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            @media only screen and (max-width: 575px) {
                font-size: 1rem;
                height: 4rem;
                padding: 1.2rem;
            }
        }
    }
}
// END //

// FIRST PAGE //
.first {
    background: #F5F7FB;
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 2rem;

    &-wrap {


        &__ttl {
            font-size: 3.6rem;
            line-height: 4.2rem;
            text-align: center;
            margin-bottom: 2rem;
            color: #000;

            @media only screen and (max-width: 575px) {
                font-size: 2.4rem;
                line-height: 3rem;
            }
        }

        &__subttl {
            font-size: 1.6rem;
            line-height: 1.9rem;
            text-align: center;
            color: #696969;

            @media only screen and (max-width: 575px) {
                font-size: 1.4rem;
                line-height: 1.6rem;
            }
        }
    }
}

.first-filters {
    margin-bottom: 5rem;
    position: relative;

    @media only screen and (max-width: 1199px) {
        margin-bottom: 2rem;
    }

    .filters-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10rem;
        height: 5rem;
        border-radius: 10rem;
        background-color: #F7F7FA;
        position: relative;
        cursor: pointer;
        box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.2);

        @media only screen and (min-width: 1200px) {
            display: none;
        }
    }

    .filters-form {
        //display: flex;
        justify-content: flex-start;
        align-items: center;

        @media only screen and (min-width: 1200px) {
            display: flex!important;
        }

        @media only screen and (max-width: 1199px) {
            display: none;
            flex-direction: column;
            align-items: flex-start;
            width: 30rem;
            background-color: #fff;
            padding-top: 2rem;
        }

        .input-wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media only screen and (max-width: 1199px) {
                margin-bottom: 1.6rem;
            }

            .input-txt__label {
                display: block;
                width: 3rem;
                height: 3rem;
                background-color: #F7F7FA;
                border-radius: 50%;
                position: relative;
                cursor: pointer;
                margin-right: 1rem;

                &::after {
                    content: "";
                    width: 1.1rem;
                    height: 1.1rem;
                    background-image: url('../img/icon/icon-find.svg');
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .input-txt {
                font-family: "HC";
                border: unset;
                background-color: unset;
                font-size: 1.6rem;
                line-height: 1.9rem;
                color: #000000;
                padding-right: 2rem;

                &::placeholder {
                    color: #000;
                }
            }
        }

        .select-wrap {
            padding: 0 3rem;
            height: 5rem;
            display: flex;
            align-items: center;
            position: relative;
            width: 20%;

            @media only screen and (max-width: 1439px) {
                padding: 0 1.5rem;
            }

            @media only screen and (max-width: 1199px) {
                margin-bottom: 1.6rem;
                width: 100%;
            }

            &::after {
                content: "";
                height: 100%;
                width: .1rem;
                border-radius: .5rem;
                background-color: #E5E5E5;
                position: absolute;
                left: 0;
            }

        }

        .input-btn {
            height: 5rem;
            background: #59579A;
            border-radius: 10rem;
            padding: 1.8rem 8rem;
            border: unset;

            font-family: "HC-bold";
            font-size: 1.4rem;
            line-height: 1.6rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #FFFFFF;

        }
    }
}

// SELECT STYLES //
.select2-container {
    width: 100%!important;
    max-width: 25rem;
}

.header-wrap__lang {
    width: 100%;
    max-width: 4rem;
    margin-right: 1rem;

    @media only screen and (max-width: 1199px) {
        margin-left: auto;
    }

    .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: #B1AEBE;
        font-family: "HC-bold";
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow b {
        background-image: url('../img/icon/icon-select-arrow-silver.svg');
    }
}

.select-wrap:nth-child(2) .select2-container {
    max-width: 10rem;

    @media only screen and (max-width: 1199px) {
        max-width: 14rem;
    }
}
.select-wrap:nth-child(3) .select2-container {
    max-width: 13.5rem;

    @media only screen and (max-width: 1199px) {
        max-width: 14rem;
    }
}
.select-wrap:nth-child(4) .select2-container {
    max-width: 7.5rem;

    @media only screen and (max-width: 1199px) {
        max-width: 14rem;
    }
}
.select-wrap:nth-child(5) .select2-container {
    max-width: 5.5rem;

    @media only screen and (max-width: 1199px) {
        max-width: 14rem;
    }
}
.select2-container--default .select2-selection--single {
    background-color: unset;
    border: unset;
    border-radius: unset;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 0;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #000;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: unset;
    background-image: url('../img/icon/icon-select-arrow.svg');
    background-repeat: no-repeat;
    width: .7rem;
    height: .6rem;
    transition: all .4s ease;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border: unset;
    transform: rotate(-180deg);
}
.select2-dropdown {
    border: unset;
    box-shadow: 0px .4rem 2rem rgba(89, 87, 154, 0.5);
    border-radius: .5rem;
}
.select2-container--open .select2-dropdown--below {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    top: 1rem!important;
}
.select2-results__option[aria-selected] {
    font-family: "HC";
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #696969;
    text-align: center;
    transition: all .4s ease;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: unset;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: #59579A;
    background-color: unset;
}
.select2-results__option:first-of-type.select2-results__option--highlighted[aria-selected] {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
}
.select2-results__option:last-of-type.select2-results__option--highlighted[aria-selected] {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

// END //

// MODAL WINDOW //
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1;
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    width: 70%;
    height: 60%;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;

    @media only screen and (max-width: 1440px) {
        width: 60%;
        height: 50%;
    }

    @media only screen and (max-width: 1200px) {
        width: 90%;
        height: 40%;
    }

    @media only screen and (max-width: 767px) {
        //width: 90%;
        height: 36%;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .close {
        position: absolute;
        top: -5%;
        right: -3%;
        cursor: pointer;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: #fff;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 0.3rem;
            height: 80%;
            background-color: #000;
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);
        }
    }
}
@-webkit-keyframes animatetop {
    from {top:-30rem; opacity:0} 
    to {top:50%; opacity:1}
}
@keyframes animatetop {
    from {top:-30rem; opacity:0}
    to {top:50%; opacity:1}
}
// END //

.first-trainer {
    margin-bottom: 12rem;

    @media only screen and (max-width: 991px) {
        margin-bottom: 5rem;
    }

    .t-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            width: 100%;
            max-width: 100%;
            padding: 3rem 2rem 3rem 5rem;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.2);
            border-radius: .5rem;
            margin-bottom: 1rem;
            position: relative;
            transition: all .4s ease;

            &:hover {
                box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.4);
            }

            &:last-of-type {
                margin-bottom: 5rem;

                @media only screen and (max-width: 991px) {
                    margin-bottom: 3rem;
                }
            }

            @media only screen and (max-width: 1439px) {
                padding: 2rem;
            }

            @media only screen and (max-width: 991px) {
                flex-direction: column;
            }

            .block-left {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-right: 2rem;

                @media only screen and (max-width: 991px) {
                    order: 2;
                    padding-right: 0;
                }

                &__characteristic {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    margin-bottom: 3rem;

                    @media only screen and (max-width: 1199px) {
                        max-width: 60rem;
                        flex-wrap: wrap;
                        margin-bottom: 0;
                    }

                    @media only screen and (max-width: 991px) {
                        max-width: unset;
                        width: 100%;
                    }

                    @media only screen and (max-width: 575px) {
                        flex-wrap: unset;
                        flex-direction: column;
                    }

                    .characteristic-info {
                        font-family: "HC-bold";
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                        color: #696969;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        @media only screen and (max-width: 1199px) {
                            margin-bottom: 2rem;
                            width: 50%;
                        }

                        @media only screen and (max-width: 575px) {
                            width: 100%;
                            margin-bottom: 1.6rem;
                        }

                        &:not(:last-of-type) {
                            margin-right: 5rem;

                            @media only screen and (max-width: 1439px) {
                                margin-right: 2rem;
                            }

                            @media only screen and (max-width: 1199px) {
                                margin-right: 0em;
                            }
                        }

                        .bolder {
                            color: #000;
                            margin-left: .5rem;
                        }

                        .characteristic-icon {
                            position: relative;
                            width: 3rem;
                            height: 3rem;
                            background: #F5F7FB;
                            border-radius: 50%;
                            margin-right: 1rem;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 1.4rem;
                                height: 1.4rem;
                                background-image: url('../img/icon/icon-book.png');
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }

                .desc-link__wrap {
                    text-decoration: none;
                }

                &__desc {
                    max-width: 100.9rem;
                    font-size: 1.8rem;
                    line-height: 2.1rem;
                    color: #000000;
                    margin-bottom: 3rem;

                    @media only screen and (max-width: 575px) {
                        font-size: 1.4rem;
                    }
                }

                &__links {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    @media only screen and (max-width: 575px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .links {
                        text-decoration: none;
                        font-size: 1.6rem;
                        line-height: 1.9rem;
                        color: #59579A;
                        border-bottom: 1px solid #59579A;
                        padding-bottom: .3rem;

                        @media only screen and (max-width: 575px) {
                            font-size: 1.4rem;
                        }

                        &:not(:last-of-type) {
                            margin-right: 3rem;

                            @media only screen and (max-width: 575px) {
                                margin-right: 0;
                                margin-bottom: 2rem;
                            }
                        }

                        &.link-video {
                            position: relative;
                            margin-left: 3rem;

                            &::after {
                                content: "";
                                background-image: url('../img/icon/icon-yt.svg');
                                background-repeat: no-repeat;
                                position: absolute;
                                top: 50%;
                                left: -13%;
                                transform: translateY(-50%);
                                width: 1.972rem;
                                height: 1.4rem;
                            }
                        }
                    }
                }
            }

            .block-right {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-left: 5rem;
                position: relative;

                @media only screen and (max-width: 1439px) {
                    padding-left: 2rem;
                }

                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-left: 0;
                    padding-bottom: 2rem;
                    margin-bottom: 2rem;
                }

                &::after {
                    content: "";
                    width: .1rem;
                    height: 100%;
                    background-color: #E5E5E5;
                    border-radius: .5rem;
                    position: absolute;
                    left: 0;

                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: .1rem;
                        bottom: 0;
                    }
                }

                &__wrap {
                    width: 10rem;
                    height: 10rem;
                    margin-bottom: 2rem;
                    border-radius: 50%;
                    box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.5);

                    img {
                        border-radius: 50%;
                    }

                    @media only screen and (max-width: 991px) {
                        margin-bottom: 1.6rem;
                    }
                }

                &__name {
                    font-family: "HC-bold";
                    font-size: 2.4rem;
                    line-height: 2.7rem;
                    color: #000000;
                    margin-bottom: 2rem;

                    @media only screen and (max-width: 991px) {
                        margin-bottom: 1.6rem;
                    }
                }

                &__price {
                    font-size: 1.8rem;
                    line-height: 2.1rem;
                    color: #000000;
                    margin-bottom: 2rem;

                    @media only screen and (max-width: 991px) {
                        margin-bottom: 1.6rem;
                    }
                }

                &__get {
                    outline: none;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: "HC-bold";
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #FFFFFF;

                    width: 20rem;
                    height: 5rem;
                    background: #F26725;
                    border-radius: 10rem;
                }
            }
    }
}

.first-features {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 6rem;

    @media only screen and (max-width: 991px) {
        justify-content: space-around;
    }

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-of-type) {
            margin-right: 21.7rem;

            @media only screen and (max-width: 1439px) {
                margin-right: 15rem;
            }

            @media only screen and (max-width: 1199px) {
                margin-right: 10rem;
            }

            @media only screen and (max-width: 991px) {
                margin-right: 3rem;
            }

            @media only screen and (max-width: 767px) {
                margin-right: 0;
                margin-bottom: 5rem;
            }
        }

        .feature-wrap {
            width: 25rem;
            height: 25rem;
            background: #F5F7FB;
            border-radius: 50%;
            margin-bottom: 5rem;
            position: relative;

            @media only screen and (max-width: 991px) {
                width: 20rem;
                height: 20rem;
            }

            @media only screen and (max-width: 767px) {
                margin-bottom: 2rem;
            }

            .feature-img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &.f-img1 {
                    top: 43%;
                    left: 45%;

                    @media only screen and (max-width: 991px) {
                        width: 9rem;
                    }
                }

                &.f-img2 {
                    top: 64%;
                    left: 62%;

                    @media only screen and (max-width: 991px) {
                        width: 10rem;
                    }
                }

                &.f-img3 {
                    left: 57%;

                    @media only screen and (max-width: 991px) {
                        width: 12rem;
                        left: 59%;
                    }
                }

                &.f-img4 {

                    @media only screen and (max-width: 991px) {
                        width: 12rem;
                    }
                }
            }
        }

        .feature-ttl {
            font-family: "HC-bold";
            font-size: 2.4rem;
            line-height: 2.7rem;
            color: #59579A;
        }
    }
}
// END //

// TRAINER ONE PAGE //
.trainer-bg__wrap {
    position: relative;
    overflow: hidden;

    .calendar-bg {
        position: absolute;
        z-index: -1;
        bottom: -10%;
        left: 0;
        width: 100%;

        @media only screen and (max-width: 1439px) {
            bottom: 0;
        }

        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
}
.trainer-wrap {
    position: relative;
}
.trainer-cards__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
}
.trainer-cards {
    padding-top: 5rem;
    padding-bottom: 3rem;

    @media only screen and (max-width: 575px) {
        padding-top: 3rem;
    }

    &__main {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px .4rem 2rem rgba(89, 87, 154, 0.1);
        border-radius: .5rem;
        margin-bottom: 3rem;
        padding: 5rem;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 1199px) {
            padding: 3rem;
        }

        @media only screen and (max-width: 991px) {
            padding: 2rem;
        }

        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }

        .main-left {
            display: flex;
            align-items: flex-start;

            @media only screen and (max-width: 1023px) {
                align-items: center;           
            }

            @media only screen and (max-width: 767px) {
                justify-content: center;
                margin-bottom: 2.5rem;
            }

            @media only screen and (max-width: 575px) {
                flex-direction: column;
                align-items: center;
                margin-bottom: 1.5rem;
            }

            &__wrap {
                width: 20rem;
                height: 20rem;
                margin-right: 7rem;
                border-radius: 50%;
                box-shadow: 0 .4rem 2rem rgba(89, 87, 154, 0.5);

                @media only screen and (max-width: 1199px) {
                    margin-right: 3.5rem;
                }

                @media only screen and (max-width: 991px) {
                    width: 15rem;
                    height: 15rem;
                }

                @media only screen and (max-width: 575px) {
                    margin-right: 0;
                }

                img {
                    border-radius: 50%;
                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media only screen and (max-width: 991px) {
                    max-width: 23rem;   
                }

                .info-name {
                    font-family: "HC-bold";
                    font-size: 4.8rem;
                    line-height: 5.5rem;
                    color: #59579A;
                    margin-top: 1.5rem;
                    margin-bottom: 1rem;

                    @media only screen and (max-width: 1023px) {
                        margin-bottom: 0;
                    }

                    @media only screen and (max-width: 991px) {
                        font-size: 3.6rem;
                        line-height: 4.4rem;
                    }
                }

                .info-options {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
                    max-width: 47rem;

                    @media only screen and (max-width: 1199px) {
                        max-width: 44rem;
                    }

                    

                    &__option {
                        width: 50%;
                        font-family: "HC-bold";
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                        color: #696969;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        margin-top: 2rem;

                        @media only screen and (max-width: 1023px) {
                            width: 100%;
                            margin-top: 1rem;
                        }

                        .bolder {
                            color: #000;
                            margin-left: .5rem;
                        }

                        .option-icon {
                            position: relative;
                            width: 3rem;
                            height: 3rem;
                            background: #F5F7FB;
                            border-radius: 50%;
                            margin-right: 1rem;

                            &::after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 1.4rem;
                                height: 1.4rem;
                                background-image: url('../img/icon/icon-book.png');
                                background-repeat: no-repeat;
                            }
                        }
                    }
                }
            }
        }

        .main-right {
            padding-left: 5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            @media only screen and (max-width: 991px) {
                padding-left: 2.5rem;       
            }

            @media only screen and (max-width: 767px) {
                padding-left: 0;
                padding-top: 2.5rem;
            }

            @media only screen and (max-width: 575px) {
                padding-top: 1.5rem;
            }

            &::after {
                content: "";
                width: .1rem;
                height: 100%;
                background-color: #E5E5E5;
                border-radius: .5rem;
                position: absolute;
                left: 0;

                @media only screen and (max-width: 767px) {
                    width: 100%;
                    height: .1rem;
                    top: 0;
                }
            }

            &__price {
                font-size: 1.8rem;
                line-height: 2.1rem;
                color: #000000;
                margin-bottom: 2rem;
            }

            &__booking {
                outline: none;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "HC-bold";
                font-size: 1.4rem;
                line-height: 1.6rem;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #FFFFFF;

                width: 20rem;
                height: 5rem;
                background: #F26725;
                border-radius: 10rem;
            }
        }
    }

    .video-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media only screen and (max-width: 991px) {
            flex-direction: column;
            align-items: center;           
        }
    }

    &__video {
        width: 100%;
        max-width: 70rem;
        height: 39.4rem;
        background-image: url('../img/trainer-video__bg.jpg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
        box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.1);
        margin-right: 3rem;
        position: relative;

        @media only screen and (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 3rem;
        }

        @media only screen and (max-width: 767px) {
            height: 32rem;
        }

        @media only screen and (max-width: 575px) {
            height: 28rem;
        }

        .video-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 6rem;
            height: 6rem;
            background-image: url('../img/icon/trainer-yt-play.svg');
            cursor: pointer;
        }
    }

    &__list {
        width: 100%;
        max-width: 63.5rem;
        min-height: 39.4rem;
        box-shadow: 0 .4rem 2rem rgba(89, 87, 154, 0.1);
        border-radius: .5rem;

        @media only screen and (max-width: 991px) {
            min-height: 34rem;
        }

        @media only screen and (max-width: 767px) {
            min-height: 30rem;
        }

        .list-ttl {
            display: block;
            width: 100%;
            font-family: "HC-bold";
            font-size: 2.4rem;
            line-height: 2.7rem;
            text-align: center;
            color: #B1AEBE;
            background-color: #F5F7FB;
            padding: 2.2rem 0;

            @media only screen and (max-width: 575px) {
                font-size: 1.8rem;
                line-height: 2.1rem;
            }
        }

        .list-items {
            background-color: #fff;
            padding: 3rem 4rem;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            @media only screen and (max-width: 1199px) {
                padding: 3rem;
            }

            @media only screen and (max-width: 575px) {
                padding: 2rem 1rem;
                flex-direction: column;
            }

            ul {
                list-style: none;
                padding: 0;

                &:first-of-type {
                    margin-right: 5rem;

                    @media only screen and (max-width: 1199px) {
                        margin-right: 2rem;
                    }

                    @media only screen and (max-width: 991px) {
                        margin-right: 5rem;
                    }

                    @media only screen and (max-width: 575px) {
                        margin-right: 0;
                        margin-bottom: 2rem;
                    }
                }

                li {
                    font-size: 1.6rem;
                    line-height: 1.9rem;
                    color: #59579A;
                    position: relative;

                    @media only screen and (max-width: 575px) {
                        font-size: 1.4rem;
                        line-height: 1.6rem;
                    }

                    &:not(:last-of-type) {
                        margin-bottom: 3rem;

                        @media only screen and (max-width: 767px) {
                            margin-bottom: 2rem;
                        }
                    }

                    &::after {
                        content: "";
                        height: .1rem;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        bottom: -.5rem;
                        background-color: #59579A;
                        border-radius: .5rem;
                    }
                }
            }
        }
    }
}
.trainer-info {
    background: #F5F7FB;
    border-radius: .5rem;
    padding: 5rem 0;
    margin-bottom: 6.7rem;

    @media only screen and (max-width: 991px) {
        margin-bottom: 3rem;
    }

    @media only screen and (max-width: 575px) {
        padding: 3rem 0;
    }

    &__block {

        .info {
            column-count: 2;
            column-gap: 8rem;
            font-size: 1.8rem;
            line-height: 2.1rem;
            color: #000000;

            @media only screen and (max-width: 1199px) {
                column-gap: 4rem;
            }

            @media only screen and (max-width: 767px) {
                column-count: 1;       
            }

            @media only screen and (max-width: 575px) {
                font-size: 1.4rem;
                line-height: 1.6rem;
            }
        }
    }

    .trainer-more {
        width: 100%;
        height: 2rem;
        position: relative;

        .more,
        .less {
            font-size: 1.6rem;
            line-height: 1.9rem;
            color: #59579A;
            position: absolute;
            bottom: 0;
            left: 56.5%;
            transform: translateX(-50%);

            @media only screen and (max-width: 1439px) {
                left: 57%;
            }

            @media only screen and (max-width: 991px) {
                left: 59%;
            }

            @media only screen and (max-width: 767px) {
                left: 0;
                transform: unset;
            }
        }
    }
}

.trainer-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;

    &__ttl {
        font-family: "HC-bold";
        font-size: 2.4rem;
        line-height: 2.7rem;
        color: #B1AEBE;
        margin-bottom: 3rem;

        @media only screen and (max-width: 575px) {
            font-size: 1.8rem;
            line-height: 2.1rem;
        }
    }

    .calendar-info {
        font-size: 1.8rem;
        line-height: 2.1rem;
        color: #000000;
        margin-bottom: 2rem;

        @media only screen and (max-width: 575px) {
            font-size: 1.4rem;
            line-height: 1.6rem;
        }
    }

    .calendar-buy {
        margin-bottom: 2rem;
        width: 20rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F26725;
        border-radius: 10rem;
        border: none;

        font-family: "HC-bold";
        font-size: 1.4rem;
        line-height: 1.6rem;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
    }

    .calendar-desc {
        width: 100%;
        max-width: 39.9rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-align: center;
        color: #696969;

        @media only screen and (max-width: 575px) {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }
}

.calendar-block {
    width: 100%;
    max-width: 73rem;
    min-height: 40.6rem;
    background: #FFFFFF;
    box-shadow: 0 .4rem 2rem rgba(89, 87, 154, 0.1);
    border-radius: .5rem;
    padding: 2.6rem 2rem 2rem 2rem;
    margin-bottom: 2rem;
    position: relative;

    @media only screen and (max-width: 575px) {
        padding: 2.6rem 0 0 0;
    }

    .calendar-block__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7rem;
        background-color: #F5F7FB;
    }

    .calendar-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 57rem;
        margin: 0 auto;
         
        &__week {
            font-family: "HC-bold";
            font-size: 1.8rem;
            line-height: 2.1rem;
            color: #59579A;
            margin-bottom: 4.6rem;
            width: 100%;
            display: block;
            text-align: center;

            @media only screen and (max-width: 575px) {
                font-size: 1.4rem;
                line-height: 1.6rem;
            }
        }

        &__days {
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            width: 100%;
            max-width: 57rem;

            @media only screen and (max-width: 575px) {
                justify-content: center;
            }

            .booking-block {
                display: flex;
                flex-direction: column;
                align-items: center;

                &:not(:last-of-type) {
                    
                    @media only screen and (max-width: 575px) {
                        margin-right: 1.5rem;
                    }
                }

                &__day {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    transition: all .2s ease;
                    cursor: pointer;
                    margin-bottom: 3.1rem;

                    & > .calendar-day:checked ~ .day-name::after {
                        opacity: 1;
                    }

                    & > .calendar-day:checked ~ .day-name,
                    & > .calendar-day:checked ~ .day-date {
                        color: #fff;
                    }

                    
                    
                    &:hover {
                        .day-name,
                        .day-date {
                            color: #fff;
                        }

                        .day-name {

                            &::after {
                                opacity: 1;
                            }
                        }
                    }

                    .day-name {
                        margin-bottom: 1.1rem;

                        &::after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            z-index: -1;
                            transform: translate(-50%, -50%);
                            height: 6.7rem;
                            width: 5.9rem;
                            background: #59579A;
                            border-radius: 2rem;
                            opacity: 0;
                            transition: all .4s ease;

                            @media only screen and (max-width: 767px) {
                                height: 5.5rem;
                                width: 4.3rem;
                            }

                            @media only screen and (max-width: 767px) {
                                height: 5rem;
                                width: 3rem;
                            }
                        }
                    }

                    .day-name,
                    .day-date {
                        font-family: "HC-bold";
                        font-size: 1.6rem;
                        line-height: 1.8rem;
                        color: #B1AEBE;

                        @media only screen and (max-width: 575px) {
                            font-size: 1.2rem;
                            line-height: 1.4rem;
                        }
                    }   
                }

                &__time {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .time {
                        margin-bottom: 2rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        
                        & > .time-checkbox:disabled ~ .time-value {
                            color: #CAC9EB;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                        & > .time-checkbox:disabled ~ .time-value::after {
                            opacity: 0;
                        }

                        & > .time-checkbox:checked ~ .time-value {
                            color: #fff;
                        }
                        & > .time-checkbox:checked ~ .time-value::after {
                            opacity: 1;
                        }
                        &:hover > .time-checkbox:checked::after {
                            content: "";
                            position: absolute;
                            z-index: 2;
                            top: -2.2rem;
                            left: 50%;
                            transform: translateX(-50%);
                            background-image: url('../img/icon/icod-arr-desc.svg');
                            background-repeat: no-repeat;
                            width: 1.2rem;
                            height: 1.2rem;

                            @media only screen and (max-width: 575px) {
                                display: none;
                            }
                        }
                        &:hover > .time-checkbox:checked::before {
                            content: attr(data-title);
                            width: 16rem;
                            height: 4.5rem;
                            position: absolute;
                            z-index: 2;
                            top: -6.5rem;
                            left: 50%;
                            transform: translateX(-50%);
                            font-family: "HC";
                            font-size: 1.6rem;
                            line-height: 1.9rem;
                            color: #F26725;
                            background-color: #fff;
                            border-radius: .5rem;
                            box-shadow: 0px 4px 20px rgba(89, 87, 154, 0.5);
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @media only screen and (max-width: 575px) {
                                font-size: 1.2rem;
                                line-height: 1.4rem;
                                top: unset;
                                bottom: 1rem;
                                width: 11rem;
                                height: 3rem;
                            }
                        }

                        &:hover {

                            .time-value {
                                color: #fff;

                                &::after {
                                    opacity: 1;
                                }
                            }

                        }

                        &-checkbox {
                            position: relative;
                        }

                        &-value {
                            font-family: "HC-bold";
                            font-size: 1.6rem;
                            line-height: 1.8rem;
                            color: #59579A;
                            position: relative;
                            transition: all .2s ease;
                            cursor: pointer;

                            @media only screen and (max-width: 575px) {
                                font-size: 1.2rem;
                                line-height: 1.4rem;
                            }

                            &::after {
                                content: "";
                                position: absolute;
                                z-index: -1;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 5.9rem;
                                height: 4rem;
                                background: #F26725;
                                border-radius: 2rem;
                                opacity: 0;
                                transition: all .4s ease;

                                @media only screen and (max-width: 767px) {
                                    width: 5rem;
                                    height: 3rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.slick-prev,
.slick-next {
    right: 8.5rem;
    z-index: 3;
    top: .8rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #FFF;

    @media only screen and (max-width: 575px) {
        right: 3rem;
    }

    @media only screen and (max-width: 374px) {
        right: 1rem;
    }

    &:hover {
        background-color: #fff;
    }

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../img/icon/slider-btn-arr.svg');
        background-repeat: no-repeat;
        width: .5rem;
        height: .8rem;
    }
}
.slick-prev {
    left: unset;
    right: 12.5rem;

    @media only screen and (max-width: 575px) {
        right: 7.5rem;
    }

    @media only screen and (max-width: 374px) {
        right: unset;
        left: 1rem;
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-180deg);
    }
}
// END //

// BOOKING PAGE //
.booking-wrap {
    position: relative;

    .booking-bg {
        width: 100%;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: -10%;

        @media only screen and (max-width: 1439px) {
            bottom: 0;
        }

        @media only screen and (max-width: 991px) {
            display: none;
        }
    }
}

.booking {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 4rem;

    &-trainer {
        width: 100%;
        max-width: 73rem;
        background: #F5F7FB;
        border-radius: .5rem;
        padding: 3rem 6.8rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 2rem;

        @media only screen and (max-width: 575px) {
            padding: 2rem;
            flex-direction: column;
        }

        &__wrap {
            width: 100%;
            max-width: 10rem;
            height: 10rem;
            margin-right: 3rem;
            border-radius: 50%;

            @media only screen and (max-width: 575px) {
                margin-right: 0;
                margin-bottom: 2rem;
            }

            img {
                border-radius: 50%;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 575px) {
                align-items: center;
            }

            .info-name {
                font-family: "HC-bold";
                font-size: 2.4rem;
                line-height: 2.7rem;
                color: #B1AEBE;
                margin-bottom: 1rem;
            }

            .info-txt {
                font-size: 1.6rem;
                line-height: 1.9rem;
                color: #696969;

                @media only screen and (max-width: 575px) {
                    text-align: center;
                }
            }
        }
    }
}
// END //

// BOOKING FORM //
.booking-form {
    width: 100%;
    max-width: 73rem;
    background: #FFFFFF;
    box-shadow: 0 .4rem 2rem rgba(89, 87, 154, 0.1);
    border-radius: .5rem;
    padding: 4rem 6.8rem 3rem 6.8rem;
    margin-bottom: 13.5rem;
    position: relative;

    @media only screen and (max-width: 575px) {
        padding: 3rem;
    }

    @media only screen and (max-width: 423px) {
        padding: 2rem;
    }

    &__steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            top: 34%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url('../img/booking-dashed-line.svg');
            background-repeat: no-repeat;
            width: 100%;
            max-width: 30.4rem;
            height: .1rem;

            @media only screen and (max-width: 767px) {
                max-width: 21rem;
            }

            @media only screen and (max-width: 575px) {
                top: 30%;
                max-width: 19rem;
            }

            @media only screen and (max-width: 423px) {
                max-width: 15rem;
            }
        }

        .step {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1;

            &-number {
                margin-bottom: 1rem;
                width: 4.8rem;
                height: 4.8rem;
                background-color: #F5F7FB;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 3rem;
                    height: 3rem;
                    background: #CAC9EB;
                    border-radius: 50%;
                    transition: all .4s ease;
                }

                &__wrap {
                    font-family: "HC-bold";
                    font-size: 1.4rem;
                    line-height: 1.6rem;
                    color: #FFFFFF;
                    position: relative;
                    z-index: 1;
                }
            }

            &-txt {
                font-family: "HC-bold";
                font-size: 1.4rem;
                line-height: 1.6rem;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #B1AEBE;
                transition: all .4s ease;

                @media only screen and (max-width: 575px) {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                    max-width: 9rem;
                    text-align: center;
                }
            }

            &.active > .step-number::after {
                background: #59579A;
            }

            &.active > .step-txt {
                color: #59579A;
            }
        }
    }

    .tab {
        display: none;

        &-forms {
            width: 100%;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(18rem, 48.3%));
            grid-gap: 2rem;

            @media only screen and (max-width: 767px) {
                grid-template-columns: repeat(auto-fit, minmax(18rem, 48.7%));
                grid-gap: 1rem;
            }

            @media only screen and (max-width: 575px) {
                grid-template-columns: repeat(auto-fit, minmax(18rem, 100%));
            }

            .booking-inp__wrap {
                position: relative;

                &:first-of-type {
                    grid-column: 1 / -1;
                }

                .booking-inp {
                    width: 100%;
                }
            }

            .select-booking__wrap {
                width: 100%;
                height: 5.9rem;
                background: #F5F7FB;
                border-radius: .5rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 0 2rem;

                .select2-container {
                    
                    @media only screen and (max-width: 575px) {
                        max-width: 100%;
                    } 
                }

                @media only screen and (max-width: 767px) {
                    padding: 0 1rem;
                }

                @media only screen and (max-width: 575px) {
                    padding: 0 2rem;
                }
            }
        }

        &-calendar {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & > .calendar-block {
                box-shadow: unset;
            }
        }
    }

    &__info {
        position: absolute;
        bottom: -4rem;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.8rem;
        line-height: 2.1rem;
        text-align: center;
        color: #000000;
    }

    &__submit {
        position: absolute;
        bottom: 6.5rem;
        left: 50%;
        transform: translateX(-50%);
        

        .submit-btn {
            border: none;
            width: 20rem;
            height: 5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F26725;
            border-radius: 10rem;

            font-family: "HC-bold";
            font-size: 1.4rem;
            line-height: 1.6rem;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
    }

    &__desc {
        position: absolute;
        bottom: -16rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 39.9rem;
        font-size: 1.4rem;
        line-height: 1.6rem;
        text-align: center;
        color: #696969;
    }
}
// END //
// FORMS ELEMENT //

.error {
    position: absolute;
    bottom: 0;
    left: 0;
}

.form-inp {
    border: unset;
    height: 5.9rem;
    background: #F5F7FB;
    border-radius: .5rem;
    padding: 2rem;
    font-size: 1.6rem;
    line-height: 1.9rem;

    @media only screen and (max-width: 767px) {
        padding: 1rem;
    }

    @media only screen and (max-width: 575px) {
        padding: 2rem;
    }

    &::placeholder {
        color: #B1AEBE;
    }
}
// END //

// FOOTER //
.footer {
    background-color: #59579A;

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media only screen and (max-width: 991px) {
            flex-direction: column;
            align-items: center;
        }

        &__logo {
            width: 8.4rem;
            height: 8rem;
        }

        &__copy {
            margin-left: 3rem;
            font-size: 1.4rem;
            line-height: 1.6rem;
            color: #FFFFFF;

            @media only screen and (max-width: 991px) {
                margin-left: 0;
                margin-top: 3rem;
            }

            @media only screen and (max-width: 575px) {
                text-align: center;
                margin-top: 2rem;
                line-height: 1.8rem;
            }
        }

        &__more {
            margin-left: auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media only screen and (max-width: 991px) {
                margin-top: 3rem;
                width: 100%;
                justify-content: center;
            }

            @media only screen and (max-width: 575px) {
                flex-wrap: wrap;
                max-width: 27rem;
                margin: 2rem auto 0 auto;
            }

            .more-link {
                text-decoration: none;
                margin-right: 3rem;
                font-family: "HC-bold";
                font-size: 1.6rem;
                line-height: 1.8rem;
                color: #F26725;

                @media only screen and (max-width: 575px) {
                    width: 100%;
                    text-align: center;
                    margin-right: 0;
                    margin-bottom: 2rem;
                }
            }

            .more-social {
                text-decoration: none;
                position: relative;
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                background-color: #47458E;

                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-repeat: no-repeat;
                }

                &.facebook {

                    &::after {
                        content: "";
                        width: 1rem;
                        height: 2rem;
                        background-image: url('../img/icon/icon-fb.png');
                    }
                }

                &.insta {

                    &::after {
                        width: 2rem;
                        height: 2rem;
                        content: "";
                        background-image: url('../img/icon/icon-insta.png');
                    }
                }
                
                &:not(:last-child) {
                    margin-right: 1rem;
                }

            }

        }
    }
}
// END //